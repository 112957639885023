const formTypes = [
  "text",
  "number",
  "date",
  "checkbox",
  "catalog",
  "static_text",
  "table",
  "registry_catalog"
];
const geoTypes = ["point", "polygon", "line", "screen"];

const fNextWs = (get) => {
  return (item) => {
    if (get.wsType == "all") return true;
    if (!item.next_wses.length) return true;
    return item.next_wses.includes(get.nextWs);
  };
};

const fType = (types) => {
  return (field) => {
    return types.includes(field.type);
  };
};

const fFindById = (id) => (field) => field._id === id;

export default {
  // Common
  localeServiceName: (state) => state.wsData.service_name,
  territoryCenter: (state) => state.wsData.territory_center,
  tsrId: (state) => state.wsData.tsr_id,
  asId: (state) => state.wsData.as_id,
  nextWs: (state) => state.nextWs,
  nextWses: (state) => state.wsData.next_wses,
  nextWsEmps: (state) => state.nextWsEmps,
  tsrLocale: (state) => state.wsData.tsr_locale,
  wsType: (state) => state.wsData.ws_type,
  hasReturn: (state) => state.wsData.return,
  wsId: (state) => state.wsData.ws_id,

  // Catalogs
  catalogList: (state) => state.catalogsList,

  // Input fields
  inputFields: (state, get) =>
    state.wsData.input_fields
      ?.filter(fNextWs(get))
      .sort((a, b) => a.order - b.order),
  geoInputFields: (_, get) => get.inputFields?.filter(fType(geoTypes)),
  formInputFields: (_, get) => get.inputFields?.filter(fType(formTypes)),
  hasFormInputFields: (_, get) => get.formInputFields?.length,
  hasGeoInputFields: (_, get) => get.geoInputFields?.length,

  // View fields
  viewFields: (state) => state.wsData.view_fields?.sort((a, b) => a.order - b.order),
  geoViewFields: (_, get) => get?.viewFields?.filter(fType(geoTypes)),
  formViewFields: (_, get) => get?.viewFields?.filter(fType(formTypes)),
  hasFormViewFields: (_, get) => get.formViewFields?.length,
  hasGeoViewFields: (_, get) => get.geoViewFields?.length,

  // Docs
  inputDocs: (state, get) => state.docsData.input?.filter(fNextWs(get)),
  uploadInputDocs: (_, get) => get.inputDocs?.filter((d) => d.upload),
  generateInputDocs: (_, get) => get.inputDocs?.filter((d) => !d.upload),
  viewDocs: (state) => state.docsData.view?.filter((d) => d.file),
  signDocIds: (state) => state.docsData.sign_ids,
  hasViewDocs: (_, get) => get.viewDocs && get.viewDocs.length,
  hasUploadDocs: (_, get) => get.uploadInputDocs?.length,
  hasGenerateDocs: (_, get) => get.generateInputDocs?.length,
  successGenerate: (state) => state.successGenerate,

  // Blocks
  hasViewData: (_, get) => get.hasFormViewFields || get.hasViewDocs,
  hasGeoData: (_, get) => get.hasGeoInputFields || get.hasGeoViewFields,
  hasInputData: (_, get) => get.hasFormInputFields || get.hasUploadDocs,

  // Methods
  getInputFieldById: (_, get) => (id) => get.inputFields.find(fFindById(id)),
  getViewFieldById: (_, get) => (id) => get.viewFields.find(fFindById(id)),
  getInputFieldValueById: (state) => (id) => state?.fieldsValue?.[id],
  getCatalogById: (state) => (id) => state.catalogsList[id],

  getInputDocById: (_, get) => (id) => get.inputDocs.find((d) => d.id === id),
  getViewDocById: (_, get) => (id) => get.viewDocs.find((d) => d.id === id),
  errorById: (state) => (id) => state.errors?.[id],

  isFieldBlocked: (state) => (id) => Object.values(state.wsData?.formula_blocked_fields || {})
    .some(i => i.includes(id)),
  getBlockedColumns: (state) => (id) => Object.values(state.wsData?.formula_blocked_fields || {})
    .reduce((acc, i) => [...acc, ...i.filter(fc => fc.includes(id)).map(fc => fc.replace(`${id}.`, ''))], []),

  returnMessage: (state) => state.wsData?.return_message,

  loaderModalShow: (state) => state.loaderModalShow,
  showRevokeModal: (state) => state.showRevokeModal
};
