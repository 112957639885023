export default () => ({
  wsData: {},
  nextWs: null,
  nextWsEmps: {},
  docsData: {},
  fieldsValue: {},
  errors: {},
  autofieldFunctions: {},
  catalogsList: {},
  registryCatalogsList: {},
  successGenerate: false,
  loaderModalShow: false,
  showRevokeModal: false
});
