export const SET_WS_DATA = 'SET_WS_DATA';
export const SET_NEXT_WS = 'SET_NEXT_WS';
export const SET_NEXT_WS_EMP = 'SET_NEXT_WS_EMP';
export const SET_DOCS_DATA = 'SET_DOCS_DATA';
export const SET_FIELDS_VALUE = 'SET_FIELDS_VALUE';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_FIELD_VALUE_BY_ID = 'SET_FIELD_VALUE_BY_ID';
export const ADD_CATALOG_LIST = 'ADD_CATALOG_LIST';
export const ADD_REGISTRY_CATALOG_LIST = 'ADD_REGISTRY_CATALOG_LIST';
export const SET_FILE_TO_DOC = 'SET_FILE_TO_DOC';
export const SET_SIGNED_FILE = 'SET_SIGNED_FILE';
export const CLEAR_DIRTY_FIELDS_VALUE = 'CLEAR_DIRTY_FIELDS_VALUE';
export const CLEAR_DIRTY_DOCS = 'CLEAR_DIRTY_DOCS';
export const RESET = 'RESET';
export const SET_AUTOFIELD_FUNCTIONS = 'SET_AUTOFIELD_FUNCTIONS';
export const SET_FIELD_DIRTY_BY_ID = 'SET_FIELD_DIRTY_BY_ID';
export const SET_SUCCESS_GENERATE = 'SET_SUCCESS_GENERATE';
export const SET_ERROR_BY_ID = 'SET_ERROR_BY_ID';
export const TOGGLE_LOADER_MODAL = 'TOGGLE_LOADER_MODAL';
export const TOGGLE_REVOKE_ERROR_MODAL = 'TOGGLE_REVOKE_ERROR_MODAL';
